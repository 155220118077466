.footer {
    color: #ffffff;
    padding: 50px 0;
    display: flex;            /* New: Use Flexbox */
    justify-content: center;  /* New: Center the text horizontally */
    align-items: center;      /* New: Center the text vertically */
    font-weight: 600;
    font-size: 13px;
    bottom: 0;
  }
  
  .copyright {
    font-family: 'Chakra Petch';
    margin-right: 20px; /* New: Add some space on the right */
  }
  