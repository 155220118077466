body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-family: 'Chakra Petch';
  border: 2px solid #FFEBA6;
  table-layout: fixed;
}

table th{
  padding: 7px !important;
  opacity: 1;
  text-align: center;
  padding-bottom: 10px;
  font-size: 24px;
  color: #1b1b1b;
  background: #C59F1A;
  background-image: linear-gradient(#C59F1A 0%, #FFEBA6 50%, #C59F1A 100%);
}

table thead tr {
  border-spacing: 0 30px;
}

table tbody{
  color: #ffffff;
  font-size: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  table tbody {
    font-size: 16px;
  }

  table th {
    font-size: 20px;
  }
}