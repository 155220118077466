.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #1D1D1D;
}

.main-content {
    flex: 1;
    width: 100%;
}

#daily-bonus, #monthly-bonus{
    background-color: #2c2c2c;
}
